<template>
  <div class="header flex gap-13 items-center py-4 border-b">
    <img
      class="w-30 h-30"
      src="/content/images/illustrations/binoculars-with-rings.png"
      alt="dashboard"
    />
    <div class="header-description space-y-4 w-full">
      <p class="font-normal text-bb-text-default w-full max-w-172">
        Get insights into paid and organic search. Analyze competitors, monitor new entrants, and track ranking trends
        for a thorough understanding of the search result landscape over time.
      </p>
      <div class="flex justify-between items-center">
        <div>
          <p class="text-black">
            There are
            <span class="rounded-full bg-purple-0 text-bb-brand-purple px-3 py-0.5"> {{ monitoringJobsCount }}</span>
            tasks actively running.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'MainHeader',
  component: {
    Toast,
  },
  props: {
    monitoringJobsCount: {
      type: Number,
      default: 0,
    },
    keywordStatusAvailability: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('site', ['selectedSite']),
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <page>
    <page-section
      full-width
      bottom-border
      class="h-16"
    >
      <div class="flex justify-between items-end">
        <page-title>
          <div class="flex items-center gap-3">
            <ic-radar
              :size="24"
              class="text-bb-brand-purple"
            />
            <p>Competitor Monitoring</p>
          </div>
        </page-title>
      </div>
    </page-section>

    <!-- MAIN CONTAINER-->
    <div
      ref="main_container"
      class="main-container base-scrollbar relative bg-white"
      :class="{ 'stop-scrolling': isPageLoading }"
    >
      <!--MAIN HEADER-->
      <main-header
        class="bg-white"
        :monitoring-jobs-count="activeMonitoringJobs || 0"
        :keyword-status-availability="keywordStatusAvailability"
      />

      <!--SUB HEADER - Overview and Infringements Detector-->
      <sub-header
        v-if="hasActiveMonitoringJob && keywordStatusAvailability && !isPageLoading"
        class="sticky top-0 z-40"
        :monitored-campaign-locations="monitoredCampaignLocations"
        :monitored-campaign-names="monitoredCampaignNames"
        :monitoring-job-keywords="monitoringJobKeywords"
        :total-infringements="infringementSummary.newCount"
        :active-since="activeSince"
        :paused-on="pausedOn"
        :date-range="dateRange"
        :is-monitoring-job-stopped="isMonitoringJobStopped"
        :created-at="monitoringJob.createdAt"
        @pick-date="pickDate"
        @update-monitoring-job-status="updateMonitoringJobStatus"
      />

      <!--LOADER - Whole Page-->
      <div
        v-if="isPageLoading"
        class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
      >
        <brightbid-loader />
      </div>
      <div v-else>
        <div v-if="isGeneratingTask">
          <brightbid-spinner
            :title="spinnerTitleAndDescription.title"
            :description="spinnerTitleAndDescription.description"
          />
        </div>
        <div v-if="!isGeneratingTask">
          <router-view />
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import PageTitle from '@/components/shared/PageTitle.vue'

import MainHeader from '@/views/site/search/competitor_monitoring/MainHeader.vue'
import SubHeader from '@/views/site/search/competitor_monitoring/SubHeader.vue'

import IcRadar from '@/components/icon/brightbid/ic-radar.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import BrightbidSpinner from '@/components/loader/BrightbidSpinner.vue'

import { mapState, mapActions, mapGetters } from 'vuex'

import * as Sentry from '@sentry/vue'
import { getErrorStatus } from '@/utils/util'

import dayjs from 'dayjs'

const DEFAULT_CREDIT_LIMIT = 100000

export default {
  name: 'CompetitorMonitoringIndex',
  components: {
    Page,
    PageSection,
    PageTitle,
    BrightbidLoader,
    IcRadar,
    MainHeader,
    SubHeader,
    BrightbidSpinner,
  },
  data() {
    return {
      keywordStatusAvailability: false,
      isPageLoading: false,
      isCompetitorMonitoringInitialized: false,
      siteMonitoringJobs: [],
      shouldFetchFreshData: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdminUser']),
    ...mapState('site', ['selectedSite']),
    ...mapState('search', { campaigns: 'campaignsAll' }),
    ...mapState('competitorMonitoring', [
      'dateRange',
      'competitorInsights',
      'monitoringJob',
      'competitionRanking',
      'topCompetitors',
      'frequencyRanks',
      'savingsInsights',
      'isOverviewLoading',
      'infringementSummary',
      'infringements',
      'whitelist',
    ]),
    isGeneratingTask() {
      return (this.hasActiveMonitoringJob || this.isCompetitorMonitoringInitialized) && !this.keywordStatusAvailability
    },
    isMonitoringJobStopped() {
      return this.monitoringJob.id && !this.monitoringJob.initialized
    },
    spinnerTitleAndDescription() {
      if (this.isGeneratingTask && !this.isMonitoringJobStopped) {
        return {
          title: 'Generating Task...',
          description:
            'This may require a few minutes, so please wait! Feel free to navigate away from this page at any time.',
        }
      }
      return {
        title: '',
        description: '',
      }
    },
    activeMonitoringJobs() {
      if (!this.monitoringJob.id || !this.monitoringJob.initialized) return 0
      return 1
    },
    hasActiveMonitoringJob() {
      if (!this.monitoringJob) return false
      return this.monitoringJob.id
    },
    monitoredCampaignNames() {
      if (!this.monitoringJob) return ''
      return [this.monitoringJob.name]
    },
    monitoredCampaignLocations() {
      if (!this.monitoringJob) return []
      return this.monitoringJob.geoLocations.map(location => location.name)
    },
    activeSince() {
      if (!this.monitoringJob) return ''
      return dayjs(this.monitoringJob.createdAt).format('YYYY-MM-DD')
    },
    pausedOn() {
      if (!this.monitoringJob) return null
      return dayjs(this.monitoringJob.updatedAt).format('YYYY-MM-DD')
    },
    monitoringJobKeywords() {
      if (!this.monitoringJob) return []
      return this.monitoringJob.keywords.map(keyword => ({
        label: keyword,
      }))
    },
  },
  async mounted() {
    /*
     * Initialize Site for Competitor Monitoring
     * If the site has not been onboarded, redirect to onboarding page
     * If the site has been onboarded, initialize competitor monitoring (Overview and Infringements Detector)
     */

    this.setPageLoading(true)

    const cmsSiteReady = await this.cmsSiteInitialization()

    if (!cmsSiteReady.isReady) {
      // what to do if not yet ready?
      console.log(cmsSiteReady.message)
      console.log(cmsSiteReady.field)
      return
    }

    // get site monitoring job
    this.siteMonitoringJobs = await this.getSiteMonitoringJob()
    const clonedMonitoringJob = structuredClone(this.siteMonitoringJobs[0])
    this.loadMonitoringJob(clonedMonitoringJob)

    if (this.monitoringJob.id) {
      // site has been onboarded
      // (if /competitor-monitoring or /onboarding, redirect to /overview)

      const routeName =
        this.$route.name === 'competitor-monitoring' || this.$route.name === 'onboarding'
          ? 'overview'
          : this.$route.name

      await this.$router.push({
        name: routeName,
      })

      await this.initializeCompetitorMonitoring()
      this.isCompetitorMonitoringInitialized = true

      setTimeout(() => {
        this.setPageLoading(false)
      }, 1000)
    } else {
      // site has not been onboarded
      await this.$router.push({ name: 'onboarding' })

      setTimeout(() => {
        this.setPageLoading(false)
      }, 1000)
    }
  },
  methods: {
    ...mapActions('competitorMonitoring', [
      'loadCompetitorInsights',
      'loadCompetitionRanking',
      'loadTopCompetitors',
      'loadSavingsInsights',
      'loadMonitoringJob',
      'loadFrequencyRanks',
      'loadInfringementSummary',
      'loadAllInfringements',
      'loadWhitelist',
      'setDateRange',
      'setOverviewLoading',
      'setInfringementsDetectorLoading',
      'resetDateRange',
    ]),
    ...mapActions('toast', ['loadToast']),
    async cmsSiteInitialization() {
      /**
       * 1. Check if the site has a URL
       * 2. Get campaigns, CMS site, and site credit
       * 3. Create site if it doesn't exist
       * 4. Set organization credit limit if it doesn't exist
       */

      // Check if the site has a URL
      if (this.selectedSite.url === '' || !Object.prototype.hasOwnProperty.call(this.selectedSite, 'url')) {
        await this.loadToast({
          title: 'Error',
          message: "Site doesn't have a URL. Please add a URL to the site.",
          type: 'error',
        })

        // handle this properly
        return {
          isReady: false,
          message: 'Site does not have a URL. Please add a URL to the site.',
          field: 'credit_limit',
        }
      }

      // Get campaigns, CMS site, and site credit
      const [cmsSite, siteCredit] = await Promise.all([this.getSiteById(), this.getOrganizationCredit()])

      if (!cmsSite) {
        // Create site if it doesn't exist
        const isSiteCreated = await this.createSite()
        if (!isSiteCreated) {
          return {
            isReady: false,
            message: 'Failed to create CMS site.',
            field: 'credit_limit',
          }
        }
      }

      if (!siteCredit) {
        // Set organization credit limit if it doesn't exist
        const createdCredit = await this.setOrganizationCreditLimit()
        if (!createdCredit) {
          return {
            isReady: false,
            message: 'Failed to set organization credit limit.',
            field: 'credit_limit',
          }
        }
      }

      // success
      return {
        isReady: true,
        message: 'Successfully initialized CMS site.',
        field: '',
      }
    },
    async initializeCompetitorMonitoring() {
      // Set the date range for paused monitoring job
      if (this.isMonitoringJobStopped) {
        // use the createdAt date as the start date and updatedAt date as the end date
        await this.setDateRange([
          new Date(this.siteMonitoringJobs[0].createdAt),
          new Date(this.siteMonitoringJobs[0].updatedAt),
        ])
      }

      const keywordStatus = await this.getKeywordStatus()

      this.keywordStatusAvailability = keywordStatus.availability

      if (!this.keywordStatusAvailability) {
        // no available data
        // just display the generating task view
        this.isCompetitorMonitoringInitialized = true
        this.setOverviewLoading(false)
        this.setInfringementsDetectorLoading(false)
        return
      }

      // Feature Flag: Infringements Detector
      let tasks = [this.loadOverview()]

      if (this.isAdminUser) {
        tasks.push(this.loadInfringementsDetector())
      }

      // TODO: skeleton loader is required for the Overview and Infringements Detector
      // this.setPageLoading(false)
      await Promise.all(tasks)
    },
    async loadInfringementsDetector() {
      this.setInfringementsDetectorLoading(true)

      const [infringementsSummary, allInfringements, whitelist] = await Promise.all([
        this.getInfringementsSummary(),
        this.getAllInfringements(),
        this.getWhitelist(),
      ])

      // SAVE TO VUEX
      this.loadInfringementSummary(infringementsSummary)
      this.loadAllInfringements(allInfringements)
      this.loadWhitelist(whitelist)

      this.setInfringementsDetectorLoading(false)
    },
    async loadOverview() {
      this.setOverviewLoading(true)

      const [competitorInsights, ranking, topCompetitors, savingsInsights, frequentRanks] = await Promise.all([
        this.getCompetitorInsights(),
        this.getRanking(),
        this.getTopCompetitors(),
        this.getSavingsInsights(),
        this.getFrequentRanks(),
      ])

      // SAVE TO VUEX
      this.loadCompetitorInsights(competitorInsights)
      this.loadCompetitionRanking(ranking)
      this.loadTopCompetitors(topCompetitors)
      this.loadSavingsInsights(savingsInsights)
      this.loadFrequencyRanks(frequentRanks)

      this.setOverviewLoading(false)
    },

    /*
     * Organization Credit Limit
     */
    async getOrganizationCredit() {
      try {
        const { data: credit } = await this.$http.get(
          `/common/organization/${this.selectedSite.site_organization_id}/cms-credit/usage`,
        )
        return credit
      } catch (e) {
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await this.loadToast({
            title: 'Error',
            message: 'Failed to get organization credit limit. Please try again later.',
            type: 'error',
          })
        }
        return null
      }
    },
    async setOrganizationCreditLimit() {
      try {
        const { data } = await this.$http.post(
          `/common/organization/${this.selectedSite.site_organization_id}/cms-credit/limit`,
          {
            credit_limit: DEFAULT_CREDIT_LIMIT,
          },
        )
        return data
      } catch (e) {
        if (e.response.data === 'Cms credit limit already exists for this organization.') {
          return true
        }
        await this.loadToast({
          title: 'Error',
          message: 'Failed to set organization credit limit. Please try again later.',
          type: 'error',
        })
        return null
      }
    },

    /*
     * CMS Site
     */
    async getSiteById() {
      try {
        const { data: site } = await this.$http.get(`/cms/site/${this.selectedSite.value}`)
        return site
      } catch (e) {
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await this.loadToast({
            title: 'Error',
            message: 'Failed to get site. Please try again later.',
            type: 'error',
          })
        }
        return null
      }
    },
    async createSite() {
      try {
        const payload = {
          id: this.selectedSite.value,
          url: this.selectedSite.url,
        }
        const { data } = await this.$http.post(`/cms/site`, payload)
        return data
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to create site. Please try again later.',
          type: 'error',
        })
        return null
      }
    },
    async getSiteMonitoringJob() {
      try {
        const { data: monitoringJob } = await this.$http.get(`/cms/site/${this.selectedSite.value}/monitoring-job/v2`)
        return monitoringJob
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get monitoring job. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Keyword Status
     */
    async getKeywordStatus() {
      try {
        const startDate = dayjs(this.siteMonitoringJobs[0].createdAt).format('YYYY-MM-DD')
        const { data: keywordStatus } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/keyword-status/availability?startDate=${startDate}`,
        )
        return keywordStatus
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get the keyword status. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Competitor Insights
     */
    async getCompetitorInsights() {
      if (Object.keys(this.competitorInsights).length > 0 && !this.shouldFetchFreshData) {
        return this.competitorInsights
      }
      try {
        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')
        const { data: competitorInsights } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/competitor/insights?startDate=${startDate}&endDate=${endDate}`,
        )
        return competitorInsights
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get competitor insights. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Top of Page
     */
    async getRanking() {
      if (this.competitionRanking.length > 0 && !this.shouldFetchFreshData) {
        return this.competitionRanking
      }
      try {
        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')
        const { data: competitionRanking } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/competitor/rank/aggregated?startDate=${startDate}&endDate=${endDate}`,
        )
        return competitionRanking
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top of page. Please try again later.',
          type: 'error',
        })
        return []
      }
    },

    /*
     * Top Competitors
     */
    async getTopCompetitors() {
      if (this.topCompetitors.length > 0 && !this.shouldFetchFreshData) {
        return this.topCompetitors
      }

      try {
        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')

        const { data: topCompetitors } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/competitor/top?startDate=${startDate}&endDate=${endDate}`,
        )
        return topCompetitors
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get top competitors. Please try again later.',
          type: 'error',
        })
        return []
      }
    },

    /*
     * Get Savings Insights
     */
    async getSavingsInsights() {
      try {
        if (Object.keys(this.savingsInsights).length > 0 && !this.shouldFetchFreshData) {
          return this.savingsInsights
        }
        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')

        const { data: savingsInsights } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/savings?start_date=${startDate}&end_date=${endDate}`,
        )
        return savingsInsights
      } catch (err) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get savings insights. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     *
     * Get frequency ranks
     */
    async getFrequentRanks() {
      try {
        if (Object.keys(this.frequencyRanks).length > 0 && !this.shouldFetchFreshData) {
          return this.frequencyRanks
        }

        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')

        const { data: frequentRanks } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/competitor/rank/split?startDate=${startDate}&endDate=${endDate}&dataPoints=12`,
        )
        return frequentRanks
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get the most frequents rank. Please try again later.',
          type: 'error',
        })
      }
    },

    /*
     * Update Monitoring Job Status (Start and Stop)
     */
    async updateMonitoringJobStatus(state) {
      try {
        this.setPageLoading(true)
        await this.$http.put(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/${state}`,
          {},
        )
        if (this.monitoringJob) {
          // Update state

          const clonedMonitoringJobs = structuredClone(this.siteMonitoringJobs[0])
          clonedMonitoringJobs.initialized = state === 'start'
          this.loadMonitoringJob(clonedMonitoringJobs)
          this.siteMonitoringJobs[0] = this.monitoringJob

          if (state === 'start') {
            await this.resetDateRange()
            this.shouldFetchFreshData = true
          } else {
            // use the createdAt date as the start date and updatedAt date as the end date
            await this.setDateRange([
              new Date(this.siteMonitoringJobs[0].createdAt),
              new Date(this.siteMonitoringJobs[0].updatedAt),
            ])
          }

          await this.initializeCompetitorMonitoring()
          await this.$router.push({
            name:
              this.$route.name === 'competitor-monitoring' || this.$route.name === 'onboarding'
                ? 'overview'
                : this.$route.name,
          })
        }
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: `Failed to ${state} the job. Please try again later.`,
          type: 'error',
        })
      } finally {
        this.setPageLoading(false)
        this.shouldFetchFreshData = false
      }
    },

    /*
     * Get Infringements Summary
     */
    async getInfringementsSummary() {
      try {
        if (Object.keys(this.infringementSummary).length > 0 && !this.shouldFetchFreshData) {
          return this.infringementSummary
        }

        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')

        const { data: infringementsSummary } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/infringement/summary?startDate=${startDate}&endDate=${endDate}`,
        )
        return infringementsSummary
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get infringements summary. Please try again later.',
          type: 'warning',
        })
      }
    },
    /*
     * Get All Infringements
     */
    async getAllInfringements() {
      try {
        if (this.infringements.length > 0 && !this.shouldFetchFreshData) {
          return this.infringements
        }

        const end = this.isMonitoringJobStopped
          ? this.siteMonitoringJobs[0]?.updatedAt ?? this.dateRange[1]
          : this.dateRange[1]

        const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
        const endDate = dayjs(end).format('YYYY-MM-DD')

        const { data: allInfringements } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/infringement?startDate=${startDate}&endDate=${endDate}`,
        )
        return allInfringements
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get all infringements. Please try again later.',
          type: 'warning',
        })
      }
    },
    /*
     * Get Whitelist
     */
    async getWhitelist() {
      try {
        if (this.whitelist.length > 0 && !this.shouldFetchFreshData) {
          return this.whitelist
        }
        const { data: whitelist } = await this.$http.get(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.monitoringJob.id}/infringement/whitelist`,
        )
        return whitelist
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to get all infringements. Please try again later.',
          type: 'warning',
        })
      }
    },

    async pickDate(dateRange) {
      // Less than the creation date
      const isLessThanCreationDate = dateRange[1] < new Date(this.siteMonitoringJobs[0].createdAt)

      // Exceeds the current date
      const isExceedsCurrentDate = dateRange[0] > new Date()

      if (isLessThanCreationDate || isExceedsCurrentDate) {
        await this.resetDateRange()
        await this.loadToast({
          title: 'Warning',
          message: 'Invalid date range. Please select a valid date range. Default date range selected.',
          type: 'warning',
        })
      }
      // this.setPageLoading(true)
      await this.setDateRange(dateRange)
      this.shouldFetchFreshData = true

      // Feature Flag: Infringements Detector
      let tasks = [this.loadOverview()]

      if (this.isAdminUser) {
        tasks.push(this.loadInfringementsDetector())
      }
      await Promise.all(tasks)

      this.shouldFetchFreshData = false
      // this.setPageLoading(false)
    },
    setPageLoading(isLoading) {
      this.isPageLoading = isLoading
    },
  },
}
</script>

<style scoped lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 167px);
}

.header {
  padding: 11px 45px;
}

.header-description {
  padding: 18px 0;
}

.form-container {
  margin: 45px;
}

.stop-scrolling {
  overflow-y: hidden;
}
</style>

<template>
  <div :class="inlineLabel ? 'flex items-center gap-2' : 'space-y-2'">
    <slot name="label">
      <div
        v-if="label"
        class="flex items-center gap-2"
      >
        <slot name="left-icon"></slot>
        <p :class="labelStyle">{{ label }}</p>
        <slot name="right-icon"></slot>
      </div>
    </slot>
    <slot
      name="chips"
      :chips="chips"
      :chip-style="chipStyle"
    >
      <div
        :class="inlineChips ? 'flex flex-wrap items-center gap-2' : ''"
        class="cursor-pointer"
      >
        <div
          v-for="(chip, index) in chips.slice(0, maxChipsToDisplay)"
          :key="index"
          class="flex items-center"
        >
          <p
            :class="[
              chipStyle,
              chip?.background ? chip.background : 'bg-neutral-0',
              chip?.color ? chip.color : 'text-neutral-900',
            ]"
          >
            {{ chip.label }}
          </p>
        </div>

        <VMenu
          v-if="maxChipsToDisplay && chips.slice(maxChipsToDisplay, chips.length).length"
          instant-move
          :distance="8"
          placement="bottom-start"
          theme="sm"
        >
          <div :class="showMoreButtonStyle">
            <p>+{{ chips.slice(maxChipsToDisplay, chips.length).length }} {{ showMoreButtonText || null }}</p>
          </div>
          <template #popper>
            <div v-close-popper>
              <div class="space-y-1">
                <div
                  v-for="(chip, index) in chips.slice(maxChipsToDisplay, chips.length)"
                  :key="index"
                  class="flex items-center"
                  :class="[
                    chipStyle,
                    chip?.background ? chip.background : 'bg-neutral-0',
                    chip?.color ? chip.color : 'text-neutral-900',
                  ]"
                >
                  {{ chip.label }}
                </div>
              </div>
            </div>
          </template>
        </VMenu>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ChipsCollection',
  props: {
    label: {
      type: String,
      default: '',
    },
    chips: {
      type: Array,
      required: true,
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    inlineChips: {
      type: Boolean,
      default: true,
    },
    labelStyle: {
      type: String,
      default: 'text-sm font-medium text-bb-text-secondary',
    },
    chipStyle: {
      type: String,
      default: 'rounded-full py-1 px-3',
    },
    maxChipsToDisplay: {
      type: Number,
      default: 1,
    },
    showMoreButtonStyle: {
      type: String,
      default:
        'h-6 w-6 flex items-center justify-center bg-purple-0 rounded-sm text-bb-brand-purple font-bold hover:text-white hover:bg-bb-brand-purple duration-200 ease-linear',
    },
    showMoreButtonText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss"></style>

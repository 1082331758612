<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00065 0.333252C3.32065 0.333252 0.333984 3.31992 0.333984 6.99992C0.333984 10.6799 3.32065 13.6666 7.00065 13.6666C10.6807 13.6666 13.6673 10.6799 13.6673 6.99992C13.6673 3.31992 10.6807 0.333252 7.00065 0.333252ZM7.00065 12.3333C4.05398 12.3333 1.66732 9.94659 1.66732 6.99992C1.66732 4.05325 4.05398 1.66659 7.00065 1.66659C9.94732 1.66659 12.334 4.05325 12.334 6.99992C12.334 9.94659 9.94732 12.3333 7.00065 12.3333ZM9.66732 9.66659H4.33398V4.33325H9.66732V9.66659Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcStop',
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.inlineLabel ? 'flex items-center gap-2' : 'space-y-2'},[_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"flex items-center gap-2"},[_vm._t("left-icon"),_c('p',{class:_vm.labelStyle},[_vm._v(_vm._s(_vm.label))]),_vm._t("right-icon")],2):_vm._e()]}),_vm._t("chips",function(){return [_c('div',{staticClass:"cursor-pointer",class:_vm.inlineChips ? 'flex flex-wrap items-center gap-2' : ''},[_vm._l((_vm.chips.slice(0, _vm.maxChipsToDisplay)),function(chip,index){return _c('div',{key:index,staticClass:"flex items-center"},[_c('p',{class:[
            _vm.chipStyle,
            chip?.background ? chip.background : 'bg-neutral-0',
            chip?.color ? chip.color : 'text-neutral-900',
          ]},[_vm._v(" "+_vm._s(chip.label)+" ")])])}),(_vm.maxChipsToDisplay && _vm.chips.slice(_vm.maxChipsToDisplay, _vm.chips.length).length)?_c('VMenu',{attrs:{"instant-move":"","distance":8,"placement":"bottom-start","theme":"sm"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{directives:[{name:"close-popper",rawName:"v-close-popper"}]},[_c('div',{staticClass:"space-y-1"},_vm._l((_vm.chips.slice(_vm.maxChipsToDisplay, _vm.chips.length)),function(chip,index){return _c('div',{key:index,staticClass:"flex items-center",class:[
                  _vm.chipStyle,
                  chip?.background ? chip.background : 'bg-neutral-0',
                  chip?.color ? chip.color : 'text-neutral-900',
                ]},[_vm._v(" "+_vm._s(chip.label)+" ")])}),0)])]},proxy:true}],null,false,3282770132)},[_c('div',{class:_vm.showMoreButtonStyle},[_c('p',[_vm._v("+"+_vm._s(_vm.chips.slice(_vm.maxChipsToDisplay, _vm.chips.length).length)+" "+_vm._s(_vm.showMoreButtonText || null))])])]):_vm._e()],2)]},{"chips":_vm.chips,"chipStyle":_vm.chipStyle})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
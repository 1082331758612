<template>
  <div class="w-full">
    <div class="max-w-105 w-full text-center mx-auto my-60">
      <spinning-brightbid-logo size="100" />
      <slot>
        <div>
          <h5 class="h5">{{ title }}</h5>
          <p class="font-normal">
            {{ description }}
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import SpinningBrightbidLogo from '@/components/icon/brightbid/spinning-brightbid-logo.vue'

export default {
  name: 'GeneratingTask',
  components: { SpinningBrightbidLogo },
  props: {
    title: {
      type: String,
      default: 'Loading...',
    },
    description: {
      type: String,
      default: 'Please wait while we generate the data',
    },
  },
}
</script>

<style scoped lang="scss"></style>

import { render, staticRenderFns } from "./ic-donut-chart.vue?vue&type=template&id=1d35c741&scoped=true"
import script from "./ic-donut-chart.vue?vue&type=script&lang=js"
export * from "./ic-donut-chart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d35c741",
  null
  
)

export default component.exports
<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9922 2.69385V5.72385C16.3822 6.21385 18.9922 9.11385 18.9922 12.6438C18.9922 13.5438 18.8122 14.3938 18.5122 15.1838L21.1122 16.7138C21.6722 15.4738 21.9922 14.0938 21.9922 12.6438C21.9922 7.46385 18.0422 3.19385 12.9922 2.69385ZM11.9922 19.6438C8.12219 19.6438 4.99219 16.5138 4.99219 12.6438C4.99219 9.11385 7.60219 6.21385 10.9922 5.72385V2.69385C5.93219 3.19385 1.99219 7.45385 1.99219 12.6438C1.99219 18.1638 6.46219 22.6438 11.9822 22.6438C15.2922 22.6438 18.2222 21.0338 20.0422 18.5538L17.4422 17.0238C16.1622 18.6238 14.2022 19.6438 11.9922 19.6438Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcDonutChart',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>

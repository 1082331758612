<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3279_13102)">
      <path
        d="M8.5 8.64L13.77 12L8.5 15.36V8.64ZM6.5 5V19L17.5 12L6.5 5Z"
        fill="#3E4552"
      />
    </g>
    <defs>
      <clipPath id="clip0_3279_13102">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'IcLive',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

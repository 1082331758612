<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.74 19.3122C21.15 17.5822 22 15.3822 22 12.9822C22 7.46218 17.52 2.98218 12 2.98218C6.48 2.98218 2 7.46218 2 12.9822C2 18.5022 6.48 22.9822 12 22.9822C14.4 22.9822 16.6 22.1322 18.33 20.7222C18.6 20.5022 18.86 20.2622 19.11 20.0122C19.14 19.9822 19.16 19.9522 19.18 19.9322C19.38 19.7322 19.57 19.5222 19.74 19.3122ZM12 20.9822C7.59 20.9822 4 17.3922 4 12.9822C4 8.57218 7.59 4.98218 12 4.98218C16.41 4.98218 20 8.57218 20 12.9822C20 14.8322 19.37 16.5222 18.31 17.8822L16.88 16.4522C17.57 15.4722 17.98 14.2822 17.98 12.9922C17.98 9.68218 15.29 6.99218 11.98 6.99218C8.67 6.99218 5.98 9.68218 5.98 12.9922C5.98 16.3022 8.67 18.9922 11.98 18.9922C13.28 18.9922 14.49 18.5722 15.47 17.8622L16.89 19.2822C15.54 20.3522 13.85 20.9822 12 20.9822ZM13.92 13.4922C14.09 12.8322 13.94 12.1122 13.43 11.5922L13.41 11.5722C12.64 10.8022 11.41 10.7922 10.63 11.5322C10.62 11.5422 10.6 11.5522 10.58 11.5722C9.8 12.3522 9.8 13.6222 10.58 14.4022L10.6 14.4222C11.12 14.9322 11.85 15.0922 12.51 14.9122L14.02 16.4222C13.42 16.7822 12.73 17.0022 11.98 17.0022C9.77 17.0022 7.98 15.2122 7.98 13.0022C7.98 10.7922 9.77 9.00218 11.98 9.00218C14.19 9.00218 15.98 10.7922 15.98 13.0022C15.98 13.7322 15.77 14.4122 15.42 15.0022L13.92 13.4922Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcRadar',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

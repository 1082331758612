export const SWITCH_STATUS = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  ENABLED_BUT_INACTIVE: 'ENABLED_BUT_INACTIVE',
  DISABLED_AND_INACTIVE: 'DISABLED_AND_INACTIVE',
}

export const UNDO_TYPES = {
  UNDO: 'UNDO',
  UNDO_ALL: 'UNDO_ALL',
  UNDO_AND_REJECT: 'UNDO_AND_REJECT',
}

export const AGREEMENTS = {
  POLICY: 'policy',
  USAGE: 'usage',
}

export const COUNTRY_ID = {
  1: 'SE',
  2: 'NO',
  3: 'DK',
  4: 'GB',
}

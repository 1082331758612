<template>
  <div
    class="bg-bb-warning-100 border-2 border-bb-warning flex justify-between items-center h-19 rounded-md px-4 text-bb-warning-200"
  >
    <div class="flex items-start gap-4">
      <div>
        <ic-circle-warning />
      </div>
      <div class="text-base">
        <p class="font-medium">Competitor monitoring has been paused</p>
        <p v-if="!isAdmin">It looks like the service has been paused. Please contact customer support.</p>
      </div>
    </div>

    <merge-button-round @click="handleAction">
      <div v-if="!isAdmin">
        <p>Contact Support</p>
      </div>
      <div
        v-else
        class="flex items-center gap-2"
      >
        <div
          class="border-2 border-white rounded-full"
          style="height: 18px"
        >
          <ic-play :size="14" />
        </div>
        <p>Resume Monitoring</p>
      </div>
    </merge-button-round>
  </div>
</template>

<script>
import IcPlay from 'vue-material-design-icons/Play'
import IcCircleWarning from '@/components/icon/brightbid/ic-circle-warning.vue'
export default {
  name: 'PausedMonitoringJobBanner',
  components: { IcCircleWarning, IcPlay },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleAction() {
      if (this.isAdmin) {
        this.$emit('resume-monitoring')
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
